import React from 'react';
import api from '../../services/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
    Button,
    Col,
    Collapse,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip,
    Table, InputGroupText,
} from 'reactstrap';
import { getImageUrl } from '../util';
// import DropNCrop from '@synapsestudios/react-drop-n-crop';
import Geosuggest from '@ubilabs/react-geosuggest';
import { cleanNumericString } from '../../services/intl';
import { uploadFile } from '../../services/file';
import { LinkedinShareButton, LinkedinIcon, FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import _ from "lodash";
import IndustryFilter from "../cards/SearchFilters/IndustryFilter";
import ImageEditor from "../ImageEditor";
import Dropzone from "../Dropzone";
import ConfirmationPopup from "../ConfirmationPopup/confirmationPopup";

var ListingcategoryRemoveClicked = false;

export default class Deals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            brokerIndustry: [],
            fields: [],
            errors: {},
            success: {},
            updateimage: '0',
            listingimg: null,
            customMessage: '',
            listingimage: '',
            Listingmodal: false,
            editId: '',
            listinglist: [],
            locationvalues: [],
            location: '',
            latitude: '',
            longitude: '',
            city: '',
            state: '',
            establishedselectedOption: '',
            establishedyear: [],
            location_visibility: 30, // ShowCounty
            category_date: moment(),
            listingcollapse: false,
            listingToggle: 'none',
            Deletelistmodal: false,
            financialcollapse: false,
            businesscollapse: false,
            financialclass: '',
            businessclass: '',
            faqcollapse: false,
            faqclass: '',
            itemID: '',
            secondlevelmax: '',
            selectlabel: 1,
            ListingdropdownCategory: false,
            industrylistListing: [],
            activeTab: 1,
            active: [],
            inactive: [],
            plans: [],
            boostModalOpen: false,
            boostModalId: null,
            historical: [{
                year: 2020,
                cashflow: null,
                ebitda: null,
                revenue: null
            }],
            ttm: {
                year: 'ttm',
                cashflow: null,
                ebitda: null,
                revenue: null
            },
            faq: [{
                q: '',
                a:'',
            }],
            suggestedBuyers: true,
            deal_on_market: null,
            seller_finance_deal: false,
            privateDealModal: false,
            privateSuccessModal: false,
            privateDeals: [],
            privateDealModalOk: false,
            premium: false,
            user_type_id: null,
            fsbo: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        api.get(`console/deals`, null, true).then(list => {
            this.setState({ list });
        });
        let establishedyeararray = [];
        for (let i = 1950; i <= moment().year(); i++) {
            let temp = {
                "value": i,
                "label": i
            };
            establishedyeararray.push(temp);
        }
        this.setState({ establishedyear: establishedyeararray.reverse() });

        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    this.setState({ industrylistListing: res });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
    }

    ListingCategorytoggle = () => {
        if (ListingcategoryRemoveClicked) {
            ListingcategoryRemoveClicked = !ListingcategoryRemoveClicked
            return;
        }
        this.setState({ ListingdropdownCategory: !this.state.ListingdropdownCategory });
    }
    arrowtoggleListing = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.industrylistListing;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({ industrylistListing: categorydata });
    }
    parentCategoryListing(e) {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];
        let parenti = 0;
        let childi = 0;

        // eslint-disable-next-line
        this.state.industrylistListing.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parenti++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        childi++;
                    }
                });
            }
        });


        let categorydata = this.state.industrylistListing;
        if (type === 'parent') {
            if (e.target.checked === true) {

                if (parenti < 2 && childi < 2) {
                    if (parenti === 1 && childi === 1) {
                        this.setState({ secondlevelmax: 1 });
                    } else {
                        categorydata[splitid].checked = true;
                        this.setState({ secondlevelmax: '', selectlabel: 0 });
                    }
                    // eslint-disable-next-line
                    /*categorydata[splitid].children.map((lists, i) => {
                        categorydata[splitid].children[i].checked = true;
                    });*/
                } else {
                    this.setState({ secondlevelmax: 1 });
                }

            } else {
                categorydata[splitid].checked = false;
                categorydata[splitid].partialed = false;
                if (parenti === 1 && childi === 1) {
                    this.setState({ selectlabel: 0 });
                } else if (parenti === 0 && childi === 1) {
                    this.setState({ selectlabel: 1 });
                } else if (parenti === 1 && childi === 0) {
                    this.setState({ selectlabel: 1 });
                } else {
                    this.setState({ selectlabel: 0 });
                }
                this.setState({ secondlevelmax: '' });
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                    categorydata[splitid].children[i].checked = false;
                });
            }
            this.setState({ industrylistListing: categorydata });
        } else {
            let child_id = splitValue[2];
            if (e.target.checked === true) {

                if (childi < 2 && parenti < 2) {
                    if (parenti === 1 && childi === 1) {
                        this.setState({ secondlevelmax: 1 });
                    } else {
                        categorydata[splitid].checked = false;
                        categorydata[splitid].partialed = true;
                        categorydata[splitid].children[child_id].checked = true;
                        this.setState({ secondlevelmax: '', selectlabel: 0 });
                    }
                    /*let checkboxlength = categorydata[splitid].children.length;
                    let count_checked = 0;
                    // eslint-disable-next-line
                    categorydata[splitid].children.map((lists, i) => {
                        if (categorydata[splitid].children[i].checked === true) {
                            count_checked++;
                        }
                    });
                    if (count_checked === checkboxlength) {
                        categorydata[splitid].checked = true;
                    }*/
                } else {
                    this.setState({ secondlevelmax: 1 });
                }

            } else {
                if (parenti === 1 && childi === 1) {
                    this.setState({ selectlabel: 0 });
                } else if (parenti === 0 && childi === 1) {
                    this.setState({ selectlabel: 1 });
                } else if (parenti === 1 && childi === 0) {
                    this.setState({ selectlabel: 1 });
                } else {
                    this.setState({ selectlabel: 0 });
                }

                let count_checked = 0;
                // eslint-disable-next-line
                categorydata[splitid].children.map((lists, i) => {
                  if (categorydata[splitid].children[i].checked === true) {
                    count_checked++;
                  }
                });
                if (count_checked === 1) {
                  categorydata[splitid].partialed = false;
                }else{
                  categorydata[splitid].partialed = true;
                }

                categorydata[splitid].checked = false;
                categorydata[splitid].children[child_id].checked = false;
                this.setState({ secondlevelmax: '' });
            }
            this.setState({ industrylistListing: categorydata });
        }

    }

    checkValidationForCategory() {
        let parenti = 0;
        let childi = 0;

        // eslint-disable-next-line
        this.state.industrylistListing.map((industrySelected, i) => {
            if (industrySelected.checked === true) {
                parenti++;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    if (industryChildren.checked === true) {
                        childi++;
                    }
                });
            }
        });
        if (parenti === 1 && childi === 1) {
            this.setState({ selectlabel: 0 });
        } else if (parenti === 0 && childi === 1) {
            this.setState({ selectlabel: 0 });
        } else if (parenti === 1 && childi === 0) {
            this.setState({ selectlabel: 0 });
        } else {
            this.setState({ selectlabel: 1 });
        }
    }
    removeListingCategoryClick(e) {
        ListingcategoryRemoveClicked = true;
        let categorydata = this.state.industrylistListing;
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];
        if (type === 'parentselected') {
            categorydata[splitid].checked = false;
            // eslint-disable-next-line
            categorydata[splitid].children.map((lists, i) => {
                categorydata[splitid].children[i].checked = false;
            });
        } else {
            let child_id = splitValue[2];
            categorydata[splitid].children[child_id].checked = false;
        }
        this.setState({ industrylistListing: categorydata });
        this.checkValidationForCategory();
    }

    handleEditListingClick = async (e, id) => {
        e.preventDefault();
        if (id !== '') {
            document.getElementById('loaderlistingview').setAttribute("style", "display: block;");
            await fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            })
            .then(r => r.json())
            .then((res) => {
                document.getElementById('loaderlistingview').setAttribute("style", "display: none;");
                this.Listingtoggle();

                
                let fields = this.state.fields.slice();
                fields["listingtitle"] = res.name;
                fields["referencenumber"] = res.referencenumber;
                fields["companyname"] = res.company_name;
                fields["revenue"] = cleanNumericString(res.revenue);
                fields["price"] = cleanNumericString(res.price) === '0' ? '' : cleanNumericString(res.price);
                fields["ebitda"] = cleanNumericString(res.ebitda);
                fields["cashflow"] = cleanNumericString(res.cashflow);
                fields["ppe"] = cleanNumericString(res.ppe);
                fields["real_estate"] = cleanNumericString(res.real_estate);
                fields["inventory"] = cleanNumericString(res.inventory);
                fields["description"] = res.description;
                fields["reasonselling"] = res.reason_for_selling;
                fields["facilities"] = res.facilities;
                fields["financing"] = res.financing;
                fields["competition"] = res.competition;
                fields["growthopportunity"] = res.growth_opportunity;
                fields["ongoingsupport"] = res.ongoing_support;
                fields["video"] = res.video;
                fields['seller_financing'] = res.seller_financing;
                fields['leased_or_owned'] = res.leased_or_owned;
                fields['included_in_asking_price'] = res.included_in_asking_price;
                fields['debt_payments'] = res.debt_payments;
                fields['asset_sale'] = res.asset_sale;
                fields['competitive_advantages'] = res.competitive_advantages;
                fields['nda'] = res.nda;
                fields['teaser'] = res.teaser;
                fields['show_price'] = res.show_price;
                fields['completed'] = res.completed;
                let dataArea = [];
                let temp = '';
                if(res.location_address!==""){
                    temp = {
                        "description": res.location_address,
                        "label": res.location_address,
                        "latitude": res.latitude,
                        "longitude": res.longitude
    
                    };
                    dataArea.push(temp);
                }

                fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + res.id + '/legacyindustries', {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        'x-access-token': localStorage.getItem('token'),
                    },
                })
                    .then(r => r.json())
                    .then((legacy_industries) => {
                        const selectedIndustries = []
                        if (res.industries && res.industries.length) {
                            legacy_industries.forEach(industry => {
                                res.industries?.forEach(selectedIndustry => {
                                    if (selectedIndustry.industry_id === industry.value) {
                                        selectedIndustries.push(industry)
                                    }
                                })
                                if (industry.children && industry.children.length) {
                                    industry.children.forEach(subIndustry => {
                                        res.industries?.forEach(selectedIndustry => {
                                            if (selectedIndustry.industry_id === subIndustry.value) {
                                                selectedIndustries.push(subIndustry)
                                            }
                                        })
                                    })
                                }
                            })
                        }

                        this.setState({
                            editId: res.id,
                            fields,
                            listingimage: res.image,
                            industrylistListing: legacy_industries,
                            brokerIndustry: selectedIndustries,
                            selectlabel: res.industries.length === 0,
                            establishedselectedOption: (res.date === null) ? this.state.establishedselectedOption : {label: res.date, value: res.date},
                            locationvalues: dataArea,
                            location_visibility: res.location_visibility,
                            location: res.location_address,
                            latitude: res.latitude,
                            longitude: res.longitude,
                            want_to_sell: res.want_to_sell,
                        });

                        this.setState({
                            faq: [{
                                q: '',
                                a:'',
                            }],
                            historical: [{
                                year: 2020,
                                cashflow: '',
                                ebitda: '',
                                revenue: ''
                            }],
                        });

                        if(res.faq) {
                            const f = JSON.parse(res.faq);
                            if(f.length > 0) {
                                this.setState({
                                    faq: f,
                                });
                            }
                        }
                        
                        if(res.historical) {
                            const h = JSON.parse(res.historical);
                            const ttm = h.filter(d => d.year === 'ttm')

                            const his = h.filter(d => d.year !== 'ttm');
                            if(his.length > 0) {
                                this.setState({
                                    historical: his,
                                });
                            }
                            
                            
                            if(ttm.length === 1) {
                                this.setState({
                                    ttm: ttm[0]
                                });
                            } else {
                                this.setState({
                                    ttm: {
                                        year: 'ttm',
                                        cashflow: '',
                                        ebitda: '',
                                        revenue: ''
                                    }
                                });
                            }
                        } else {
                            
                            this.setState({
                                historical: [{
                                    year: 2020,
                                    cashflow: '',
                                    ebitda: '',
                                    revenue: ''
                                }],
                                ttm: {
                                    year: 'ttm',
                                    cashflow: '',
                                    ebitda: '',
                                    revenue: ''
                                }
                            });
                        }
                });
            },
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
        }

    }

    handlelistingValidation() {
        let fields = this.state.fields;
        let errors = {};
        let customMessage = '';
        let formIsValid = true;

       
        //listingtitle
        if (!fields["listingtitle"]) {
            formIsValid = false;
            errors["listingtitle"] = "Please enter listing title.";
        }

        if (!fields['description']) {
          formIsValid = false;
          errors['description'] = "Please enter description.";
        }

        const techCategoryIsSelected = !!this.state.industrylistListing.filter(c => {	
            return c.checked === true && c.label === 'Tech & Media';	
        }).length;

        // if(this.state.editId!==''){

        //     let listingimg = this.state.listingimg;

        //     if(listingimg.error!==null && !techCategoryIsSelected) {
        //         formIsValid = false;
        //         customMessage = "Please upload image.";
        //     }

        // }else{

        //     if(this.state.listingimage==='' && !techCategoryIsSelected) {
        //         formIsValid = false;
        //         customMessage = "Please upload image.";
        //     }

        // }

        let checkPlus = 0;

        // eslint-disable-next-line
        this.state.industrylistListing.map((industrySelected, i) => {
            if (industrySelected.checked === true && industrySelected.label !== "Tech & Media") {
                checkPlus = checkPlus + 1;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {
                    
                    if (industryChildren.checked === true && industryChildren.label !=="Advertising & Marketing" && industryChildren.label !=="Information Technology" && industryChildren.label !=="Internet" && industryChildren.label !=="Media") {
                        checkPlus = checkPlus + 1;
                    }

                });
            }
        });

        /*
        console.log(this.state.locationvalues.length);
        console.log(checkPlus);*/
        

        if(!techCategoryIsSelected && this.state.locationvalues.length===0){

            formIsValid = false;
            errors["location"] = "Please select location";
        }


        //category
        if (this.state.selectlabel === 1) {
            formIsValid = false;
            errors["category"] = "Please select relevant categories";
        }
        //price
        if (!fields["price"]) {
            //formIsValid = false;
            //errors["price"] = "Please enter asking price";
        }        
        this.setState({ errors: errors, customMessage });
        return formIsValid;
    }

    handleFormChange = (e, { name, value }) => {this.setState(prevState => ({
        ...prevState,
        [name]: value
    }), () => console.log(this.state))};

    handleChangelisting(field, e) {
        // this is so stupid... we'll change it later

        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            
            fields[field] = e.target.value;
            if (e.target.name === 'revenue') {
                let revenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = revenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'price') {
                let price = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'ebitda') {
                let ebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = ebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'cashflow') {
                let cashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = cashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'ppe') {
                let ppe = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = ppe.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'inventory') {
                let inventory = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = inventory.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'real_estate') {
                let real_estate = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = real_estate.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'debt_payments') {
                let debt_payments = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = debt_payments.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'asset_sale') {
                fields[field] = e.target.checked;
            }
            if (e.target.name === 'show_price') {
                fields[field] = e.target.checked;
            }

            this.setState({ fields });
        }
    }

    getImageDimensions = file => {
        return new Promise (function (resolved, rejected) {
            var i = new Image()
            i.onload = function(){
                resolved({w: i.width, h: i.height, src: i.src})
            };
            const reader = new FileReader();
            reader.onload = (event) => {
                i.src = event.target.result;
            };
            reader.readAsDataURL(file);
        })
    }

    onListingImageFail = failedResults => {
        this.setState({ customMessage: failedResults[0].errors[0].message });
    }

    onChangelistingimg = value => {
        const img = this.getImageDimensions(value[0])
        if (img.w >= 2100 && img.h >= 1500) {
            this.setState({
                listingimg: null,
                customMessage: 'Image dimensions must be less than 2100px width and 1500px height'
            });
        } else {
            this.setState({listingimg: value[0], customMessage: ''});
        }
    };
    handleGeosuggestChange = (location) => {
        //console.log(location);
        let dataArea = [];
        let temp = '';
        if (location !== undefined) {
            temp = {
                "location": location.label,
                "latitude": location.location.lat,
                "longitude": location.location.lng
            };
            if (this.state.locationvalues.length !== 0) {
                this.state.locationvalues.push(temp)
            } else {
                this.state.locationvalues.push(temp)
            }
            dataArea.push(temp);
            this.setState({ locationvalues: dataArea });
            let addressComponent = location.gmaps.address_components;
            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    //$('#ListingCity').val(addressComponent[j].long_name);
                    this.setState({ city: addressComponent[j].long_name });
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    //$('#ListingState').val(addressComponent[j].long_name);
                    this.setState({ state: addressComponent[j].long_name });
                }
            }
            this.setState({ location: location.label });
            this.setState({ latitude: location.location.lat });
            this.setState({ longitude: location.location.lng });
        }
        else {
            this.setState({ locationvalues: [], location: '', latitude: '', longitude: '', city: '', state: '' });
        }
    }
    handleChange = (establishedselectedOption) => {
        this.setState({ establishedselectedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (establishedselectedOption) {
            // console.log(`Selected: ${establishedselectedOption.label}`);
        }
    }
    SubmitListing = async (event, privateDeal = false) => {
        event && event.preventDefault();
        if (this.handlelistingValidation()) {
            document.getElementById('loaderlisting').setAttribute("style", "display: block;");
            let fields = this.state.fields;
            
            const establishedselectedOption = this.state.establishedselectedOption && this.state.establishedselectedOption.value ?
                this.state.establishedselectedOption.value : 
                this.state.establishedselectedOption;

            let referencenumber, companyname, ebitda, ppe, inventory, description, reasonselling, facilities, financing, competition, growthopportunity, ongoingsupport, revenue, cashflow = '';
            if (fields["referencenumber"] !== undefined) {
                referencenumber = fields["referencenumber"];
            }
            else {
                referencenumber = '';
            }
            if (fields["companyname"] !== undefined) {
                companyname = fields["companyname"];
            }
            else {
                companyname = '';
            }
            if (fields["ebitda"] !== undefined) {
                ebitda = fields["ebitda"];
            }
            else {
                ebitda = '';
            }
            if (fields["ppe"] !== undefined) {
                ppe = fields["ppe"];
            }
            else {
                ppe = '';
            }
            if (fields["inventory"] !== undefined) {
                inventory = fields["inventory"];
            }
            else {
                inventory = '';
            }
            if (fields["revenue"] !== undefined) {
                revenue = fields["revenue"];
            }
            else {
                revenue = '';
            }
            if (fields["cashflow"] !== undefined) {
                cashflow = fields["cashflow"];
            }
            else {
                cashflow = '';
            }
            if (fields["description"] !== undefined) {
                description = fields["description"];
            }
            else {
                description = '';
            }
            if (fields["reasonselling"] !== undefined) {
                reasonselling = fields["reasonselling"];
            }
            else {
                reasonselling = '';
            }
            if (fields["facilities"] !== undefined) {
                facilities = fields["facilities"];
            }
            else {
                facilities = '';
            }
            if (fields["financing"] !== undefined) {
                financing = fields["financing"];
            }
            else {
                financing = '';
            }
            if (fields["competition"] !== undefined) {
                competition = fields["competition"];
            }
            else {
                competition = '';
            }
            if (fields["growthopportunity"] !== undefined) {
                growthopportunity = fields["growthopportunity"];
            }
            else {
                growthopportunity = '';
            }
            if (fields["ongoingsupport"] !== undefined) {
                ongoingsupport = fields["ongoingsupport"];
            }
            else {
                ongoingsupport = '';
            }
            let categoryArrayData = [];
            // eslint-disable-next-line
            this.state.industrylistListing.map((industrySelected, i) => {
                if (industrySelected.checked === true) {
                    let categoryarray = {
                        "id": industrySelected.value,
                        "name": industrySelected.label,
                        "path": industrySelected.label + ">>All"
                    };
                    categoryArrayData.push(categoryarray);
                } else {
                    // eslint-disable-next-line
                    industrySelected.children.map((industryChildren, j) => {
                        if (industryChildren.checked === true) {
                            let categoryarray = {
                                "id": industryChildren.value,
                                "name": industryChildren.label,
                                "path": industrySelected.label + ">>" + industryChildren.label
                            };
                            categoryArrayData.push(categoryarray);
                        }
                    });
                }
            });

            let historical = this.state.historical.map(h => {
                return {
                    ...h,
                    revenue: h.revenue ? h.revenue : "0",
                    cashflow: h.cashflow ? h.cashflow : "0",
                    ebitda: h.ebitda ? h.ebitda : "0",
                }
            });
            
            historical.push(this.state.ttm);

            const faq = this.state.faq.filter(f => {
                return f.q && f.a;
            });
        
            let jsonlistingData = JSON.stringify({
                "id": this.state.editId,
                "referencenumber": referencenumber,
                "name": fields["listingtitle"],
                "company_name": companyname,
                "price": fields["price"] ? fields["price"] : '0',
                "completed": this.state.editId ? fields['completed'] : "0",
                "ebitda": ebitda,
                "ppe": ppe,
                "real_estate": fields['real_estate'],
                "inventory": inventory,
                "description": description,
                "reason_for_selling": reasonselling,
                "facilities": facilities,
                "financing": financing,
                "competition": competition,
                "growth_opportunity": growthopportunity,
                "ongoing_support": ongoingsupport,
                "date": establishedselectedOption,
                "location_visibility": this.state.location_visibility,
                "revenue": revenue,
                "cashflow": cashflow,
                "status": "1",
                "listing_date": this.state.category_date.format("YYYY-MM-DD"),
                "latitude": this.state.latitude,
                "longitude": this.state.longitude,
                "industries": categoryArrayData.map(c => ({ industry_id: c.id })),
                "category_date": this.state.category_date.format("YYYY-MM-DD"),
                "video": fields["video"],
                'seller_financing': fields['seller_financing'],
                'leased_or_owned': fields['leased_or_owned'],
                'included_in_asking_price': fields['included_in_asking_price'],
                'debt_payments': fields['debt_payments'],
                'asset_sale': fields['asset_sale'],
                'competitive_advantages': fields['competitive_advantages'],
                'nda': fields['nda'],
                'teaser': fields['teaser'],
                'historical': JSON.stringify(historical),
                'faq': JSON.stringify(faq),
                'show_price': fields['show_price'],
                privateDeal: privateDeal,
            });

            await fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + (this.state.editId || ""), {
                method: 'POST',
                body: jsonlistingData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then((res) => {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    if (res.status === 401) {
                        this.props.history.push('/');
                    } else {
                        return res.json().then(err => { throw err; });
                    }
                } else { return res.json() }
            })
                .then(
                    async (res) => {
                        if (this.state.listingimg?.name && this.state.listingimg?.name !== "") {
                            const imgUrl = this.cropperlistingimg.getImage().toDataURL()
                            await uploadFile(imgUrl, this.state.listingimg.name, this.state.listingimg.type, `transaction/${res.transaction_id}/photo`);
                        } else {
                            api.post(`transaction/uploadDefaulImage/${res.transaction_id}`, null, true);
                        }
                        window.Intercom('trackEvent', 'listing-updated', {id: this.state.editId, "name": fields["listingtitle"]});
                        
                        document.getElementById('loaderlisting').setAttribute("style", "display: none;");
                        let categorydata = this.state.industrylistListing;
                        // eslint-disable-next-line
                        this.state.industrylistListing.map((category, i) => {
                            categorydata[i].checked = false;
                            categorydata[i].partialed = false;
                            // eslint-disable-next-line
                            categorydata[i].children.map((lists, j) => {
                                categorydata[i].children[j].checked = false;
                            });
                        });
                        // eslint-disable-next-line
                        fields["listingtitle"] = '', fields["referencenumber"] ='', fields["companyname"] = '', fields["price"] = '', fields["ebitda"] = '', fields["ppe"] = '', fields["inventory"] = '', fields["description"] = '', fields["reasonselling"] = '', fields["facilities"] = '', fields["financing"] = '', fields["competition"] = '', fields["growthopportunity"] = '', fields["ongoingsupport"] = '', fields["revenue"] = '', fields["cashflow"] = '';
                        let success = {};
                        success["success"] = res.message;
                        this.refs.location.update('');
                        this.setState({success: success, establishedselectedOption: '', editId: '', latitude: '', longitude: '',
                            listingimg: null,
                            created_transaction_id: res.transaction_id,
                            listingimage: '', secondlevelmax: '', selectlabel: 1,
                            industrylistListing: categorydata, customMessage: ''
                        });
                        /*window.setTimeout(() => {
                            this.setState({ success: '' });
                        }, 5000);*/
                        if (document.getElementById('listingtitle') !== null) {
                            document.getElementById('listingtitle').focus();
                        }
                        document.getElementById('listingmodal').scrollIntoView(true);
                    },
                    (error) => {
                        this.setState({
                            //error
                        });
                    }
                )

            // IGOR: this is a hack for now because thumnail generation takes a second or two asynchronously
            // eventually, the thumbnail will be able to handle a missing image and retry but i'm not going to deal
            // with that in this mess.
            window.setTimeout(() => this.fetchData(), 2000);
        } else {
            let fields = this.state.fields;
            if(fields["price"]=== undefined || fields["price"] === ''){
                this.setState({ financialcollapse: true, financialclass: 'active' });
            }
            document.getElementById('listingmodal').scrollIntoView(true);
            //console.log("Form has errors.")
        }
    }
    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    clearDragNCrop = () => {
      this.setState({
        listingimg: null
      })
    };

    onApprove = id => {
        api.post('console/accept_deal/' + id, null, true).then(() => {
            this.fetchData();
        });
    }

    onReject = id => {
        api.post('console/reject_deal/' + id, null, true).then(() => {
            this.fetchData();
        });
    }

    onDelete = () => {
        api.delete(`transaction/${this.state.deleteDealId}`, {}, true).then(res => {
            this.closeConfirmationPopup()
            this.fetchData();
        });
    }

    handleOpenDelete = (id) => {
        this.setState({deleteModalOpen: true, deleteDealId: id});
    }

    closeConfirmationPopup = () => {
        this.setState({deleteModalOpen: false, deleteDealId: null});
    }

    onChangeVisibility = (checked, deal) => {
        api.post(`transaction/visibility/${deal.id}`, {
            checked
        }, true).then(() => {
            this.fetchData();
        })
    }

    Listingtoggle = () => {
        let categorydata = this.state.industrylistListing;
        // eslint-disable-next-line
        this.state.industrylistListing.map((category, i) => {
            categorydata[i].checked = false;
            categorydata[i].partialed = false;
            // eslint-disable-next-line
            categorydata[i].children.map((lists, j) => {
                categorydata[i].children[j].checked = false;
            });
        });
        this.setState({
            Listingmodal: !this.state.Listingmodal,
            editId: '',
            fields: [],
            locationvalues: [],
            location: [],
            latitude: '',
            longitude: '',
            errors: {},
            listingimg: null,
            listingimage: '',
            secondlevelmax: '',
            selectlabel: 1,
            success: '',
            industrylistTransaction: categorydata,
            brokerIndustry: [],
            customMessage: '',
            historical: [{
                year: 2020,
                cashflow: null,
                ebitda: null,
                revenue: null
            }],
            ttm: {
                year: 'ttm',
                cashflow: null,
                ebitda: null,
                revenue: null
            },
            faq: [{
                q: '',
                a:'',
            }],
        });
        window.setTimeout(() => {
            if (document.getElementById('listingtitle') !== null) {
                document.getElementById('listingtitle').focus();
            }
        }, 500);
    }
    listingcollapsestoggle(e) {
        e.preventDefault();
        this.setState({ listingcollapse: !this.state.listingcollapse });
        if (this.state.listingToggle === '') {
            this.setState({ listingToggle: 'none' });
        } else {
            this.setState({ listingToggle: '' });
        }
    }
    financialtoggle = () => {
        this.setState({ financialcollapse: !this.state.financialcollapse });
        if (this.state.financialclass === '') {
            this.setState({ financialclass: 'active' });
        } else {
            this.setState({ financialclass: '' });
        }
    }
    businesstoggle() {
        this.setState({ businesscollapse: !this.state.businesscollapse });
        if (this.state.businessclass === '') {
            this.setState({ businessclass: 'active' });
        } else {
            this.setState({ businessclass: '' });
        }
    }

    faqtoggle() {
        this.setState({ faqcollapse: !this.state.faqcollapse });
        if (this.state.faqclass === '') {
            this.setState({ faqclass: 'active' });
        } else {
            this.setState({ faqclass: '' });
        }
    }


    getUpdatedIndustryList = (item, checked) => {
        return this.state.industrylistListing.map(i =>
            i.value === item.value
                ? {...i, checked}
                : i.children && i.children.length
                    ? {...i, children: i.children.map(ic => ic.value === item.value ? {...ic, checked} : ic)}
                    : i
        )
    }
    getParentAndChildCounts = (newItems) => {
        let parentCount = 0;
        let childCount = 0;

        // eslint-disable-next-line
        newItems.forEach((industrySelected, i) => {
            if (industrySelected.children && industrySelected.children.length) {
                parentCount++;
            } else {
                childCount++;
            }
        });
        return {parentCount, childCount}
    }

    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        const industrylistListing = this.getUpdatedIndustryList(item, false)
        const {parentCount, childCount} = this.getParentAndChildCounts(brokerIndustry)

        if (parentCount === 1 && childCount === 1) {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
        } else {
            this.setState({secondlevelmax: '', selectlabel: 0});
        }
        this.setState({
            brokerIndustry,
            industrylistListing
        });
    }

    onUpdateBrokerIndustry = (items = []) => {
        const selectedItem = items.find(item => !this.state.brokerIndustry.some(bi => bi.value === item.value))
        if (!selectedItem) {
            const deselectedItem = this.state.brokerIndustry.find(bi => !items.some(item => bi.value === item.value))
            this.onRemoveBrokerIndustry(deselectedItem)
            return
        }
        const newIndustryList = this.getUpdatedIndustryList(selectedItem, true)
        const {parentCount, childCount} = this.getParentAndChildCounts(items)

        if (parentCount < 3 && childCount < 3) {
            if (parentCount === 1 && childCount === 1) {
                this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
            } else {
                this.setState({secondlevelmax: '', selectlabel: 0});
            }
        } else {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
            return
        }
        this.setState({
            brokerIndustry: items,
            industrylistListing: newIndustryList
        });
    }


    addNewHistoricalRow = () => {
        const { historical } = this.state;
        historical.push({
            year: historical.length ? historical[historical.length - 1].year - 1 : 2020,
            cashflow: null,
            ebitda: null,
            revenue: null
        });
        this.setState({
            historical
        });
    }

    removeHistoricalRow = i => {
        const { historical } = this.state;
        historical.splice(i, 1);
        this.setState({ historical });
    }

    handleChangeHistoricYear = (e, i) => {
        const { value } = e.target;
        const { historical } = this.state;

        historical[i].year = value;
        this.setState({ historical });
    }

    handleRowChange = (field, i, e) => {
        if (e.target !== '' && e.target !== undefined) {
            const { historical } = this.state;
            let newValue = e.target.value.replace(/[^0-9]/g, '');
            historical[i][field] = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({ historical });
        }
    }

    handleTTMChange = (field, e) => {
        if (e.target !== '' && e.target !== undefined) {
            const { ttm } = this.state;
            let newValue = e.target.value.replace(/[^0-9]/g, '');
            ttm[field] = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({ ttm });
        }
    }

    addNewFAQRow = () => {
        const { faq } = this.state;
        faq.push({
            q: '',
            a: '',
        });
        this.setState({
            faq
        });
    }

    removeFAQRow = i => {
        const { faq } = this.state;
        faq.splice(i, 1);
        this.setState({ faq });
    }

    handleChangeFAQ = (field, i, e) => {
        if (e.target !== '' && e.target !== undefined) {
            const { faq } = this.state;
            faq[i][field] = e.target.value;
            this.setState({ faq });
        }
    }



    

    render() {

        const getBackgroundColor = b => {
            if(b.completed === 0) {
                return 'green'
            }
            if(b.completed === 3) {
                return 'yellow'
            }
            if(b.completed === 4) {
                return 'red'
            }
        }

        const { establishedselectedOption } = this.state;

        const now = new Date().getUTCFullYear() - 1;    
        const years = Array(now - (now - 26)).fill('').map((v, idx) => now - idx);

        const locationVisOptions = [                                                        
            { value: 0, label: "Hidden" },
            { value: 10, label: "Show country only" },
            { value: 20, label: "Show state/province and country" },
            { value: 30, label: "Show county, state and country" },
            { value: 40, label: "Show city, county, state, and country" },
        ];

        const getLocationVisLabel = () => {
            return locationVisOptions.find(o => o.value === this.state.location_visibility).label;
        }

        return (
            <div>
                <div className="loader" id="loaderlistingview" style={{ display: 'none' }}><div className="spiner"></div></div>
                <Link to="/console">&larr; Back</Link>
                        <br /><br />
                        <Row>
                        <Col sm="12">
                            <h2>Proprietary Deals</h2>
                            <Table bordered hover responsive>
                                <thead style={{ textAlign: 'center' }}>
                                    <tr>
                                        <th>#</th>
                                        <th style={{maxWidth: 300}}>Name / ID</th>
                                        <th>Email</th>
                                        <th>Created At</th>
                                        <th>Price</th>
                                        <th>SDE</th>
                                        <th>EBITDA</th>
                                        <th>Revenue</th>
                                        <th>Location</th>
                                        <th>Updated At</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.list.map(s => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <td><Button id={s.id} onClick={(e) => this.handleEditListingClick(e, s.id)} size="sm" color="primary"><i className="fas fa-pen"></i></Button></td>
                                            <td style={{maxWidth: 300}}><a target="_blank" rel="noopener noreferrer" href={`/business-for-sale/${s.transaction_slug}`}>{s.name}</a> / {s.id}</td>
                                            <td>{s.guru.email}</td>
                                            <td>{moment(new Date(s.created_at)).format('LLL')}</td>
                                            <td>{s.price}</td>
                                            <td>{s.cashflow}</td>
                                            <td>{s.ebitda}</td>
                                            <td>{s.revenue}</td>
                                            <td>{s.location_address}</td>
                                            <td>{s.modified_at ? moment(new Date(s.modified_at)).format('LLL') : '-'}</td>
                                            <td style={{ backgroundColor: getBackgroundColor(s) }}>{s.isDeleted ? 'DELETED' : ''}</td>
                                            <td>
                                            {s.completed === 3 && <React.Fragment>
                                                <Button size="sm" color="primary" onClick={() => this.onApprove(s.id)}>Approve</Button>
                                                <Button style={{ marginLeft: 5 }} size="sm" color="danger" onClick={() => this.onReject(s.id)}>Reject</Button>
                                            </React.Fragment>}
                                            {(s.completed === 0 || s.completed === 2 || s.completed === 5) && <React.Fragment>
                                                <div class="custom-control custom-switch">
                                                    <input onChange={(e) => this.onChangeVisibility(e.target.checked, s)} type="checkbox" checked={s.completed === 0 ? true : false} class="custom-control-input" id={`customSwitch${s.id}`} />
                                                    <label class="custom-control-label" for={`customSwitch${s.id}`}>Public Visibility</label>
                                                </div>      
                                            </React.Fragment>}
                                                {
                                                    !s.isDeleted && <Button color="danger" style={{backgroundColor: '#fff'}} className="btn btn-outline-secondary delete-btn" onClick={() => this.handleOpenDelete(s.id)}><i className="fas fa-trash-alt"/></Button>
                                                }
                                        </td>
                                        </tr>    
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                        
                        </Row>
                <ConfirmationPopup
                    title="Delete this deal?"
                    isOpen={this.state.deleteModalOpen}
                    onClose={this.closeConfirmationPopup}
                    onCancel={this.closeConfirmationPopup}
                    onAccept={this.onDelete}
                    acceptText="Delete"
                    acceptClass="danger"
                />

                        <Modal isOpen={this.state.Listingmodal} toggle={this.Listingtoggle} className="profile-modal listing-modal" keyboard={false} backdrop="static" id="listingmodal">
                            
                            <ModalHeader toggle={this.Listingtoggle}>
                            {this.state.success["success"] === "Saved successfully!" && <p className="text-center me-4 ms-4">
                                        <h2 className="h2 mb-4">Congrats! Your listing has gone live on BizNexus. Share it now!!</h2>
                                        <div>
                                        <LinkedinShareButton style={{ padding: 10 }} url={`${process.env.REACT_APP_ROOT}business-for-sale/-/${this.state.created_transaction_id}`}>
                                            <LinkedinIcon size={48} round={true} />
                                        </LinkedinShareButton>
                                        <FacebookShareButton style={{ padding: 10 }} url={`${process.env.REACT_APP_ROOT}business-for-sale/-/${this.state.created_transaction_id}`}>
                                            <FacebookIcon size={48} round={true} />
                                        </FacebookShareButton>
                                        <TwitterShareButton style={{ padding: 10 }} url={`${process.env.REACT_APP_ROOT}business-for-sale/-/${this.state.created_transaction_id}`}>
                                            <TwitterIcon size={48} round={true} />
                                        </TwitterShareButton>
                                        </div>
                                    </p>}
                                   
                                    {this.state.success["success"] === "Saved successfully!" && <div className="divider"></div>}
                            <p className="text-center">{this.state.editId !== '' ? "Update Listing" : this.state.user_type_id === 3 ? "Add New Deal": 'Sell Your Business Anonymously'}</p>
                            </ModalHeader>
                            <ModalBody className="pt-3 pb-5 plr-0">
                                <div className="position-relative">
                                    
                                    <p className="text-center me-4 ms-4">{this.state.user_type_id === 3 ? 'New listings added must only be established businesses for sale, franchise resales' : 'Add whatever info you’re comfortable with, post your anonymous business profile and once approved you can start chatting with potential buyers and intermediaries who match by deal size, location, industry niche & more.'} </p>
                                    <p className="text-center me-4 ms-4 mb-4">{this.state.user_type_id === 3 ? '(no new franchise opportunities allowed), business real estate for sale or lease, or asset sales.' : 'Acquisition opportunities posted on BizNexus must be established businesses or franchise resales, business real estate for sale or lease, or asset sales.'}</p>
                                    
                                    <div className="loader bg-white" id="loaderlisting" style={{ display: 'none' }}><div className="spiner"></div></div>
                                    
                                    
                                    <Form onSubmit={this.SubmitListing}>
                                        <FormGroup row>
                                            <Label htmlFor="listingtitle" md={4}>Enter Title* </Label>
                                            <Col md={7}>
                                                <Input type="text" name="listingtitle" id="listingtitle" ref="listingtitle" onChange={this.handleChangelisting.bind(this, "listingtitle")} value={this.state.fields["listingtitle"]} placeholder="Enter listing title" />
                                                <span className="error  ">{this.state.errors["listingtitle"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="referencenumber" md={4}>Reference Number</Label>
                                            <Col md={7}>
                                                <Input type="text" name="referencenumber" id="referencenumber" ref="referencenumber" onChange={this.handleChangelisting.bind(this, "referencenumber")} value={this.state.fields["referencenumber"]} placeholder="Optional - Add listing/reference number here if applicable." />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="" md={4}>Listing Photo</Label>
                                            <Col md={7}>

                                                {(this.state.listingimage) &&
                                                    <div className="img-preview">
                                                        <img
                                                            src={(this.state.listingimage)
                                                                ? (this.state.listingimage.indexOf('base64') > -1)
                                                                    ? `${this.state.listingimage}`
                                                                    : (this.state.listingimage.indexOf('listing_') > -1)
                                                                        ? getImageUrl(this.state.listingimage, true)
                                                                        : getImageUrl(this.state.listingimage, true)
                                                                : process.env.PUBLIC_URL + "/images/profile_pic.png"
                                                        }
                                                            className="img-fluid" alt={this.state.fields["listingtitle"]}/>
                                                    </div>}

                                                {this.state.listingimg
                                                    ? <div className="user-banner profile-pic-banner m-0 mt-3">
                                                        <ImageEditor
                                                            zoom
                                                            image={this.state.listingimg}
                                                            setRef={ref => this.cropperlistingimg = ref}
                                                            width={300}
                                                            height={200}
                                                        />
                                                    </div>
                                                    : <div className="banner-drop profile-drop user-banner m-0 mt-3">
                                                        <Dropzone
                                                            text="Click to upload or drag JPG or PNG here"
                                                            maxFileSize={5024000}
                                                            multiple={false}
                                                            onSuccess={this.onChangelistingimg}
                                                            onFailure={this.onListingImageFail}
                                                        />
                                                    </div>
                                                }
                                                {/*<DropNCrop onChange={this.onChangelistingimg} value={this.state.listingimg} canvasHeight="140px" maxFileSize={2048000} multiple={false} instructions="Click to upload or drag JPG or PNG here" ref="cropperlistingimg" cropperOptions={this.state.cropset} />*/}
                                                <span className="error  ">{this.state.customMessage}</span>
                                                {this.state.listingimg && <p style={{
                                                    margin: '0 0 10px',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                  <Button color="danger"
                                                          size="sm"
                                                          onClick={this.clearDragNCrop}
                                                  >Clear</Button>
                                                </p>}
                                                <p style={{ fontStyle: 'italic', fontSize: 14 }}>Need help finding a good photo? <a rel="noopener noreferrer" href="https://pixabay.com/" target="_blank">Click HERE.</a></p>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="custom-menu-category" row>
                                            <Label htmlFor="category" md={4}>Choose industries*  <i style={{ margin: '0px 5px 5px 5px' }} id="categoryTooltip" className="sprite i-question"></i>
                                                <UncontrolledTooltip placement="bottom" target="categoryTooltip" autohide={false} innerClassName="areas-tooltip">Maximum 2 selection allowed</UncontrolledTooltip></Label>
                                            <Col md={7}>
                                                <Dropdown isOpen={this.state.ListingdropdownCategory} toggle={this.ListingCategorytoggle} >
                                                    <DropdownToggle tag="a" className="form-control" caret>
                                                        Select Industry
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <IndustryFilter
                                                            industriesList={this.state.industrylistListing}
                                                            selectedIndustries={this.state.brokerIndustry}
                                                            updateIndustries={this.onUpdateBrokerIndustry}
                                                            onRemoveIndustry={this.onRemoveBrokerIndustry}
                                                        />
                                                    </DropdownMenu>
                                                </Dropdown>
                                                <div className="d-flex flex-wrap align-items-center row-gutter-10 ms-1">
                                                    {this.state.brokerIndustry.map((item, i) =>
                                                        <>
                                                            <div className="mt-1 ms-1">
                                                                <button onClick={(e) => e.preventDefault()}
                                                                        className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                        style={{
                                                                            padding: '1.5px 11px',
                                                                            wordBreak: 'break-all'
                                                                        }}>
                                                                    <span className="me-2">{item.label}</span>
                                                                    <svg id={i + "_parentselected"}
                                                                         onClick={() => this.onRemoveBrokerIndustry(item)}
                                                                         className="icon"
                                                                         viewBox="0 0 14 13" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                                              d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                              fill="currentColor"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                                              d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                              fill="currentColor"/>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {this.state.secondlevelmax === "" && <span className="error  ">{this.state.errors["category"]}</span>}
                                                {this.state.secondlevelmax !== "" && <span className="error  ">Maximum 2 selection allowed</span>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="description" md={4}>Description*</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{ height: '270px' }} name="description" id="description" ref="description" onChange={this.handleChangelisting.bind(this, "description")} value={this.state.fields["description"]} placeholder="Enter description of the opportunity. Do not include email addresses, websites, and phone numbers." />
                                                <span className="error  ">{this.state.errors['description']}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="location" md={4}>Location (Optional for Internet Co's)</Label>
                                            <Col md={7}>
                                                <div className="position-relative">
                                                    <Geosuggest
                                                        inputClassName="search-input form-control"
                                                        id="location"
                                                        name="location"
                                                        ref="location"
                                                        placeholder="Enter your location"
                                                        types={['(cities)']}
                                                        onSuggestSelect={this.handleGeosuggestChange}
                                                        initialValue={this.state.location}
                                                    />
                                                </div>
                                                <span className="error  ">{this.state.errors["location"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="description" md={4}>Public Location Visibility</Label>
                                            <Col md={7}>
                                                <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                    name="location_visibility"
                                                    id="location_visibility"
                                                    isClearable={false}
                                                    onChange={e => this.setState({ location_visibility: e.value })}
                                                    options={locationVisOptions}
                                                    value={{value: this.state.location_visibility, label: getLocationVisLabel()}}
                                                    
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="nda" md={4}>Link to NDA file</Label>
                                            <Col md={7}>
                                                <Input placeholder="https://..." type="text" name="nda" id="nda" ref="nda" onChange={this.handleChangelisting.bind(this, "nda")} value={this.state.fields["nda"]} />
                                            </Col>
                                        </FormGroup>
                                        {this.state.user_type_id === 3 && <FormGroup row>
                                            <Label htmlFor="teaser" md={4}>Upload a teaser / Link
                                            <i style={{ margin: '0px 5px 5px 5px' }} id="teaserTooltip" className="sprite i-question"></i>
                                                <UncontrolledTooltip placement="bottom" target="teaserTooltip" autohide={false} innerClassName="areas-tooltip">We allow paid subscription buyers to access and download teasers for your listing.</UncontrolledTooltip>
                                            </Label>
                                            <Col md={7}>
                                                <Input type="text" name="teaser" id="teaser" ref="teaser" onChange={this.handleChangelisting.bind(this, "teaser")} value={this.state.fields["teaser"]} />
                                            </Col>
                                        </FormGroup>}
                                        <FormGroup row>
                                            <Label htmlFor="video" md={4}>Video link
                                            <i id="videoTooltip" style={{ margin: '0px 5px 5px 5px' }} className="sprite i-question"></i>
                                                <UncontrolledTooltip placement="bottom" target="videoTooltip" autohide={false} innerClassName="areas-tooltip">Feel free to add a youtube or vimeo URL to show video relevant to your listing. NOTE any listings with video not related to the specific listing will be taken down from our site.</UncontrolledTooltip>
                                            </Label>
                                            <Col md={7}>
                                                <Input type="text" name="video" id="video" ref="video" onChange={this.handleChangelisting.bind(this, "video")} value={this.state.fields["video"]} />
                                                
                                            </Col>
                                        </FormGroup>
                                        <Button className="btn-toggle" data-class={this.state.financialclass} onClick={this.financialtoggle}><i className="arrow-right"></i>&nbsp;&nbsp;&nbsp;Add Financial Details</Button>
                                        <Collapse isOpen={this.state.financialcollapse} className="mt-3">
                                            <FormGroup row>
                                                <Label htmlFor="price" md={4}>Asking Price</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="price" id="price" ref="price" onChange={this.handleChangelisting.bind(this, "price")} value={this.state.fields["price"]} />
                                                    </InputGroup>
                                                    <span className="error  ">{this.state.errors["price"]}</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="" md={4}>Do Not Show Asking Price</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <Input style={{ marginLeft: '5px', marginTop: '17px' }} type="checkbox" name="show_price" onChange={this.handleChangelisting.bind(this, "show_price")} value={this.state.fields["show_price"]} checked={this.state.fields["show_price"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="revenue" md={4}>{this.state.user_type_id === 3 ? 'Revenue' : <>
                                                Estimated Annual Revenue

                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`AnnualRevenue`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`AnnualRevenue`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Enter your approximate annual revenues so you can attract & qualify the appropriate buyers for your deal
                                                    </UncontrolledTooltip>
                                                </>} </Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="revenue" id="revenue" ref="revenue" onChange={this.handleChangelisting.bind(this, "revenue")} value={this.state.fields["revenue"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                            <Label htmlFor="cashflow" md={4}>{this.state.user_type_id === 3 ? 'SDE' : <>
                                                Estimated SDE

                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`sdeTooltip`} className="icon tooltip-icon text-secondary">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                </svg>
                                                <UncontrolledTooltip placement="bottom" target={`sdeTooltip`}
                                                                     autohide={false}
                                                                     innerClassName="areas-tooltip">
                                                    Enter your approximate SDE cash flow so you can attract & qualify the appropriate buyers for your deal. (SDE is a cash flow measure typically used for smaller deals under $600k)
                                                </UncontrolledTooltip>
                                                </>} </Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="cashflow" id="cashflow" ref="cashflow" onChange={this.handleChangelisting.bind(this, "cashflow")} value={this.state.fields["cashflow"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                            <Label htmlFor="ebitda" md={4}>{this.state.user_type_id === 3 ? 'EBITDA' : <>
                                                Estimated EBITDA

                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`ebitdaTooltip`} className="icon tooltip-icon text-secondary">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                    <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                </svg>
                                                <UncontrolledTooltip placement="bottom" target={`ebitdaTooltip`}
                                                                     autohide={false}
                                                                     innerClassName="areas-tooltip">
                                                    Enter your approximate EBITDA cash flow so you can attract & qualify the appropriate buyers for your deal. (EBITDA is a cash flow measure typically used for larger deals over $600k)
                                                </UncontrolledTooltip>
                                                </>} </Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="ebitda" id="ebitda" ref="ebitda" onChange={this.handleChangelisting.bind(this, "ebitda")} value={this.state.fields["ebitda"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="inventory" md={4}>Inventory</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="inventory" id="inventory" ref="inventory" onChange={this.handleChangelisting.bind(this, "inventory")} value={this.state.fields["inventory"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label htmlFor="financing" md={4}>Inventory included in asking price?</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                    <form>
                                                        <div><Label htmlFor="Leased">Yes</Label>
                                                        <Input type="radio"
                                                            id="Leased"
                                                            value={1}
                                                            style={{ marginLeft: '20px' }}
                                                            checked={this.state.fields['included_in_asking_price'] === true}
                                                            onChange={e => {
                                                                const { fields } = this.state;
                                                                fields.included_in_asking_price = true;
                                                                this.setState({ fields });
                                                            }}/></div>
                                                        <div><Label htmlFor="Owned">No</Label>
                                                        <Input type="radio"
                                                            id="Owned"
                                                            value={2}
                                                            style={{ marginLeft: '24px' }}
                                                            checked={!this.state.fields['included_in_asking_price']}
                                                            onChange={e => {
                                                                const { fields } = this.state;
                                                                fields.included_in_asking_price = false;
                                                                this.setState({ fields });
                                                            }}/></div>
                                                    </form>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="ppe" md={4}>FF&E</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="ppe" id="ppe" ref="ppe" onChange={this.handleChangelisting.bind(this, "ppe")} value={this.state.fields["ppe"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="real_estate" md={4}>Real Estate</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input type="text" name="real_estate" id="real_estate" ref="real_estate" onChange={this.handleChangelisting.bind(this, "real_estate")} value={this.state.fields["real_estate"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="Leased" md={4}>Leased / Owned</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                    <form>
                                                        <div><Label htmlFor="Leased">Leased</Label>
                                                        <Input type="radio"
                                                            id="Leased"
                                                            value={1}
                                                            style={{ marginLeft: '20px' }}
                                                            checked={parseInt(this.state.fields['leased_or_owned']) === 1}
                                                            onChange={e => {
                                                                const { fields } = this.state;
                                                                fields.leased_or_owned = 1;
                                                                this.setState({ fields });
                                                            }}/></div>
                                                        <div><Label htmlFor="Owned">Owned</Label>
                                                        <Input type="radio"
                                                            id="Owned"
                                                            value={2}
                                                            style={{ marginLeft: '20px' }}
                                                            checked={parseInt(this.state.fields['leased_or_owned']) === 2}
                                                            onChange={e => {
                                                                const { fields } = this.state;
                                                                fields.leased_or_owned = 2;
                                                                this.setState({ fields });
                                                            }}/></div>
                                                    </form>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="" md={4}>Total annual debt payments (confidential)<i style={{ margin: '0px 5px 5px 5px' }} id="debt" className="sprite i-question"></i>
                                                <UncontrolledTooltip placement="bottom" target="debt" autohide={false} innerClassName="areas-tooltip">If the company has debt, and we can help you fix that to get a higher selling price, we'll let you know. We will NOT show this metric on the public listing page. This is a free value-add service for {this.state.user_type_id === 3 ? 'business brokers' : 'entrepreneur'} to make businesses with problematic debt issues more sellable.</UncontrolledTooltip></Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            $
                                                        </InputGroupText>
                                                        <Input name="debt_payments" type="text" onChange={this.handleChangelisting.bind(this, "debt_payments")} value={this.state.fields["debt_payments"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="" md={4}>Is this an asset sale?</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                        <Input style={{ marginLeft: '5px', marginTop: '17px' }} type="checkbox" name="asset_sale" onChange={this.handleChangelisting.bind(this, "asset_sale")} value={this.state.fields["asset_sale"]} checked={this.state.fields["asset_sale"]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label md={4} htmlFor="history">{this.state.user_type_id === 3 ? 'Historical view' : <>
                                                Estimated historical performance

                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`HistoricalTooltip`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`HistoricalTooltip`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        Complete this to show the graph of your historical growth in these key areas
                                                    </UncontrolledTooltip>
                                                </>} </Label>
                                                <Col md={7}>
                                                    <Table responsive className="tbl-transaction">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Year</th>
                                                                <th className="text-center">Revenue</th>
                                                                <th className="text-center">SDE</th>
                                                                <th className="text-center">EBITDA</th>
                                                                <th className="text-center">#</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-center">TTM</td>
                                                                <td><input value={this.state.ttm.revenue} onChange={this.handleTTMChange.bind(this, "revenue")} style={{ height: '23px', padding: '5px' }} type="text" className="form-control" /></td>
                                                                <td><input value={this.state.ttm.cashflow} onChange={this.handleTTMChange.bind(this, "cashflow")} style={{ height: '23px', padding: '5px' }} type="text" className="form-control" /></td>
                                                                <td><input value={this.state.ttm.ebitda} onChange={this.handleTTMChange.bind(this, "ebitda")} style={{ height: '23px', padding: '5px' }} type="text" className="form-control" /></td>
                                                                <td></td>
                                                            </tr>
                                                            {this.state.historical.map((row, i) => 
                                                            <tr>
                                                                <td>
                                                                    <select value={row.year} onChange={(e) => this.handleChangeHistoricYear(e, i)}>
                                                                        {years.map(y => (
                                                                            <option value={y}>{y}</option>
                                                                        ))}
                                                                        
                                                                    </select>
                                                                </td>
                                                                <td><input value={row.revenue} onChange={this.handleRowChange.bind(this, "revenue", i)} style={{ height: '23px', padding: '5px' }} type="text" className="form-control" /></td>
                                                                <td><input value={row.cashflow} onChange={this.handleRowChange.bind(this, "cashflow", i)} style={{ height: '23px', padding: '5px' }} type="text" className="form-control" /></td>
                                                                <td><input value={row.ebitda} onChange={this.handleRowChange.bind(this, "ebitda", i)} style={{ height: '23px', padding: '5px' }} type="text" className="form-control" /></td>
                                                                <td>
                                                                <span onClick={() => this.removeHistoricalRow(i)} style={{ cursor: 'pointer' }}>X</span>
                                                                </td>
                                                            </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                    <Button onClick={() => this.addNewHistoricalRow()}>Add Year</Button>
                                                </Col>
                                            </FormGroup>
                                        </Collapse>
                                        <Button className="btn-toggle" data-class={this.state.businessclass} onClick={() => this.businesstoggle()}><i className="arrow-right"></i>&nbsp;&nbsp;&nbsp;Add Business Information</Button>
                                        <Collapse isOpen={this.state.businesscollapse} className="mt-3">
                                            <FormGroup row>
                                                <Label htmlFor="established" md={4}>Established</Label>
                                                <Col md={7}>
                                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                        name="established"
                                                        id="established"
                                                        isSearchable={true}
                                                        isClearable={false}
                                                        onChange={this.handleChange}
                                                        options={this.state.establishedyear}
                                                        value={establishedselectedOption}
                                                        ref="established"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="reasonselling" md={4}>Reason for Selling</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="reasonselling" id="reasonselling" ref="reasonselling" onChange={this.handleChangelisting.bind(this, "reasonselling")} value={this.state.fields["reasonselling"]} placeholder="Enter Reason for Selling" />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label htmlFor="competitive_advantages" md={4}>Competitive Advantages</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="competitive_advantages" id="competitive_advantages" ref="competitive_advantages" onChange={this.handleChangelisting.bind(this, "competitive_advantages")} value={this.state.fields["competitive_advantages"]} placeholder="" />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label htmlFor="facilities" md={4}>Facilities/Assets Overview</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="facilities" id="facilities" ref="facilities" onChange={this.handleChangelisting.bind(this, "facilities")} value={this.state.fields["facilities"]} placeholder="&bull; Is the real estate owned or leased?
&bull; Is the real estate included in the asking price? 
&bull; What is the building square footage?
&bull; Is the inventory included in the asking price?" 
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="financing" md={4}>Is seller financing available?</Label>
                                                <Col md={7}>
                                                    <InputGroup>
                                                    <form>
                                                        <div><Label htmlFor="Leased">Yes</Label>
                                                        <Input type="radio"
                                                            id="Leased"
                                                            value={1}
                                                            style={{ marginLeft: '20px' }}
                                                            checked={this.state.fields['seller_financing'] === true}
                                                            onChange={e => {
                                                                const { fields } = this.state;
                                                                fields.seller_financing = true;
                                                                this.setState({ fields });
                                                            }}/></div>
                                                        <div><Label htmlFor="Owned">No</Label>
                                                        <Input type="radio"
                                                            id="Owned"
                                                            value={2}
                                                            style={{ marginLeft: '24px' }}
                                                            checked={!this.state.fields['seller_financing']}
                                                            onChange={e => {
                                                                const { fields } = this.state;
                                                                fields.seller_financing = false;
                                                                this.setState({ fields });
                                                            }}/></div>
                                                    </form>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="financing" md={4}>Financing Overview
                                                {this.state.user_type_id !== 3 && <>
                                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`FinancingTooltip`} className="icon tooltip-icon text-secondary">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                        <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                    </svg>
                                                    <UncontrolledTooltip placement="bottom" target={`FinancingTooltip`}
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">
                                                        If you don’t know what financing might be available, schedule a meeting with one of lending partners to nail down options available to you and a potential buyer. Link that opens in new tab: https://www.biznexus.com/acquisition-financing
                                                    </UncontrolledTooltip></>}
                                                </Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="financing" id="financing" ref="financing" onChange={this.handleChangelisting.bind(this, "financing")} value={this.state.fields["financing"]} placeholder="Enter any available financing for the buyer." />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="competition" md={4}>Competition Overview</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="competition" id="competition" ref="competition" onChange={this.handleChangelisting.bind(this, "competition")} value={this.state.fields["competition"]} placeholder="Enter Competition" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="growthopportunity" md={4}>Growth Opportunity</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="growthopportunity" id="growthopportunity" ref="growthopportunity" onChange={this.handleChangelisting.bind(this, "growthopportunity")} value={this.state.fields["growthopportunity"]} placeholder="Enter Growth-opportunity" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="ongoingsupport" md={4}>Ongoing Support</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{ height: '100px' }} name="ongoingsupport" id="ongoingsupport" ref="ongoingsupport" onChange={this.handleChangelisting.bind(this, "ongoingsupport")} value={this.state.fields["ongoingsupport"]} placeholder="Enter Ongoing-support" />
                                                </Col>
                                            </FormGroup>
                                        </Collapse>
                                        <Button className="btn-toggle" data-class={this.state.faqclass} onClick={this.faqtoggle.bind(this)}><i className="arrow-right"></i>&nbsp;&nbsp;&nbsp;FAQ</Button>
                                        <Collapse isOpen={this.state.faqcollapse} className="mt-3">
                                            {this.state.faq.map((f, i) => <div>
                                                <FormGroup row>
                                                    <Label md={4}>Question {i + 1}</Label>
                                                    <Col md={7}>
                                                        <Input type="text" onChange={this.handleChangeFAQ.bind(this, "q", i)} value={f.q} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label md={4}>Answer {i + 1}</Label>
                                                    <Col md={7}>
                                                        <Input type="textarea" style={{ height: '100px' }} onChange={this.handleChangeFAQ.bind(this, "a", i)} value={f.a} />
                                                    </Col>
                                                </FormGroup>
                                                {i > 0 && <FormGroup row>
                                                    <Label md={4}></Label>
                                                    <Col md={7}>
                                                        <Button color="primary" className="btn" size="sm" onClick={() => this.removeFAQRow(i)}>Remove question {i + 1}</Button>
                                                    </Col>
                                                </FormGroup>}
                                            </div>)}
                                            <FormGroup row>
                                                <Label md={4}></Label>
                                                <Col md={7}><Button onClick={() => this.addNewFAQRow()}>+ Add Question</Button></Col>
                                            </FormGroup>
                                        </Collapse>
                                        <div className="text-center">
                                            <button style={{ fontSize: 16, padding: '13px 129px' }} type="submit" className="btn btn-primary w-300">{this.state.editId !== '' ? "Update Listing" : "Add Listing"}</button>
                                            {this.state.editId === '' && 
                                                <div className="text-center"><a href="/#" onClick={this.onPrivateDeal}>or add a private listing</a></div>
                                            }
                                        </div>
                                    </Form>
                                </div>
                            </ModalBody>
                        </Modal>






            </div>
        )
    }
}

