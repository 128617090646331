import React from "react";
import {Button, Modal, ModalFooter, ModalBody, ModalHeader} from "reactstrap";
import './confirmationPopup.scss'

const ConfirmationPopup = ({
   isOpen,
   onClose,
   onCancel,
   onAccept,
   title,
   description,
   cancelText,
   acceptText,
   acceptClass,
   loading,
}) => {
    return (
        <Modal size="sm" isOpen={isOpen} toggle={onClose}
               className="confirmation-popup" keyboard={true} backdrop="static">
            <ModalHeader>
                {title}
            </ModalHeader>
            {
                description
                    ? <ModalBody className="p-0 mb-2"><p style={{color: 'white', fontSize: '12px' }}>{description}</p></ModalBody>
                    : null
            }
            <ModalFooter>
                <Button
                    className="confirmation-btn"
                    type="button"
                    onClick={onCancel}
                    disabled={loading}
                >
                    {cancelText || 'Cancel'}
                </Button>
                <Button
                    className={`confirmation-btn ${acceptClass || 'success'}`}
                    type="button"
                    onClick={onAccept}
                    disabled={loading}
                >
                    {acceptText || 'Delete'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationPopup
